/* External dependencies */
import { Col, Div, Row, Text } from 'atomize';
import { navigate } from 'gatsby';
import { Trans } from 'gatsby-plugin-react-i18next';
import MarkdownIt from 'markdown-it';
import React from 'react';
import ReactMarkdownEditorLite from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';
import { useDispatch, useSelector } from 'react-redux';

// Local dependencies
import { RootState } from '@app/redux/store';
import {
  getAuthorName,
  getUserId,
  onChange,
} from '@entities/articles/lib/helper';
import {
  articleCategories,
  articleTypes,
  defaultAvatar,
} from '@entities/articles/lib/model';
import {
  createArticleRequest,
  createArticleUpdateFields,
  informationPopup,
  resetError,
} from '@entities/articles/redux/create-article-redux/action';
import Header from '@shared/ui/admin/header/Header';
import Button from '@shared/ui/button';
import FormDropdown from '@shared/ui/custom-dropdown/FormDropdown';
import FormFields from '@shared/ui/form/FormField';
import { errorMessageStyle } from '@shared/ui/form/FormStyles';
import ErrorPopup from '@shared/ui/popup/ErrorPopup';
import PrimaryPopup from '@shared/ui/popup/PrimaryPopup';
import SuccessPopup from '@shared/ui/popup/SuccessPopup';
import '../../styles/style.scss';
import Avatar from '../avatar/Avatar';

const rowStyle = {
  d: 'flex',
  m: '0 -30px 1rem -30px',
};
const collSize = '7';

const mdParser = new MarkdownIt();

export default function CreateArticle() {
  const dispatch = useDispatch();
  const {
    error,
    loading,
    isSuccess,
    isConfirmationPopupOpen,
    article,
    sendingArticle,
    categoryError,
    fullVideoUrlError,
    dataError,
    titleError,
    synopsisError,
  } = useSelector((state: RootState) => state?.createArticle);
  const { user } = useSelector((state: RootState) => state?.getUser);
  const { currentUser } = useSelector((state: RootState) => state?.login);
  const { imageUrl, fullVideoUrl } = article?.heroMedia;
  const authorImage = imageUrl ? imageUrl : null;
  const userId = getUserId(user);
  const authorName = getAuthorName(user);

  const isValidateFormError =
    categoryError ||
    dataError ||
    titleError ||
    !article?.title ||
    !article?.synopsis ||
    !imageUrl ||
    !article?.contents[0].data ||
    synopsisError;

  function updateField(name: string, value: any) {
    dispatch(createArticleUpdateFields({ [name]: value }));
  }

  function onSelectArticlyType(value) {
    dispatch(createArticleUpdateFields({ articleType: value }));
  }
  function onSelectArticlyCategory(value) {
    dispatch(createArticleUpdateFields({ category: value }));
  }

  function onSubmit() {
    dispatch(informationPopup(true));
  }

  function onContinueOperation() {
    dispatch(
      createArticleRequest({
        ...sendingArticle,
        author: { id: currentUser?.sub },
        ...userId,
      }),
    );
  }

  const handleEditorChange = ({ text }) => {
    dispatch(createArticleUpdateFields({ data: text }));
  };

  function onCancelOperation() {
    dispatch(informationPopup(false));
  }

  function onCloseSuccessPopup() {
    navigate(`/articles`);
  }

  function onCloseErrorPopup() {
    dispatch(resetError());
  }

  return (
    <Div className="container" m="0 auto">
      <Header />
      <Div p={{ t: '80px' }}>
        <Div m={{ b: '30px' }}>
          <Avatar
            author={authorName}
            image={user?.avatar || defaultAvatar}
            type={user?.__typename}
          />
        </Div>
        <Div m={{ b: '40px' }}>
          <Text textSize="subtitle" textColor="primary">
            <Trans>сreateArticle</Trans>
          </Text>
        </Div>
        <Div>
          <Row {...rowStyle} d="flex" m={{ b: '50px' }}>
            <Col size="6">
              <FormDropdown
                label={<Trans>articleType</Trans>}
                onSelectItem={onSelectArticlyType}
                withoutTranslation={true}
                items={articleTypes}
              >
                {article?.articleType}
              </FormDropdown>
              <FormDropdown
                withoutTranslation={true}
                label={<Trans>category</Trans>}
                onSelectItem={onSelectArticlyCategory}
                items={articleCategories}
              >
                {article?.category}
              </FormDropdown>

              <Row {...rowStyle}>
                <FormFields
                  colSize={collSize}
                  label={<Trans>title</Trans>}
                  error={titleError}
                  inputName="title"
                  inputType="Textarea"
                  onChange={(e) => onChange(e, updateField)}
                  inputValue={article?.title}
                  required
                />
              </Row>
              <Row {...rowStyle}>
                <FormFields
                  colSize={collSize}
                  label={<Trans>synopsis</Trans>}
                  error={synopsisError}
                  inputName="synopsis"
                  inputStyle={{ minH: '100px' }}
                  inputType="Textarea"
                  onChange={(e) => onChange(e, updateField)}
                  inputValue={article?.synopsis}
                  required
                />
              </Row>
              <Row {...rowStyle}>
                <FormFields
                  colSize={collSize}
                  label={<Trans>fullVideoUrl</Trans>}
                  error={fullVideoUrlError}
                  inputName="fullVideoUrl"
                  inputType="text"
                  onChange={(e) => onChange(e, updateField)}
                  inputValue={fullVideoUrl}
                  required
                />
              </Row>
              <Row {...rowStyle}>
                <Col size="5">
                  <Text
                    textColor="label_text_color"
                    textSize="body"
                    textTransform="uppercase"
                    textWeight="700"
                  >
                    <Trans>addBanner</Trans>
                  </Text>
                </Col>
                <Col>
                  <label className="create-article-image-input">
                    <Trans>addBanner</Trans>
                    <input
                      type="file"
                      name="imageUrl"
                      onChange={(e) => onChange(e, updateField)}
                    />
                  </label>
                </Col>
              </Row>
            </Col>
            <Col size="6">
              <Div pos="relative" m="0 auto" w="350px" h="350px">
                <img
                  className="article-author-image"
                  src={authorImage}
                  alt=""
                />
              </Div>
            </Col>
          </Row>
          <Div>
            <Div m={{ b: '30px' }}>
              <Text textColor="main_color" textSize="display2">
                <Trans>article</Trans>
              </Text>
            </Div>
            <ReactMarkdownEditorLite
              value={article?.contents?.data}
              onChange={handleEditorChange}
              renderHTML={(text) => mdParser.render(text)}
            />
            {dataError && (
              <Text textSize="caption" {...errorMessageStyle}>
                <Trans>{dataError.code}</Trans>
              </Text>
            )}
          </Div>
          <Div m={{ b: '20px' }}>
            <Row p={{ t: '3rem' }} maxW="800px">
              <Col size="4">
                <Button
                  width="100%"
                  typeColor="primary"
                  disabled={isValidateFormError || loading}
                  loading={loading}
                  onClick={onSubmit}
                >
                  <Trans>add</Trans>
                </Button>
              </Col>
            </Row>
          </Div>
          {isConfirmationPopupOpen && (
            <PrimaryPopup
              title="article"
              btnText="add"
              cancelBtnText="cancel"
              onClick={onContinueOperation}
              onClose={onCancelOperation}
              loading={loading}
            >
              <Div textColor="grey">
                <Trans>youWantToAddArticle</Trans>
              </Div>
            </PrimaryPopup>
          )}
          {isSuccess && (
            <SuccessPopup
              onSubmit={onCloseSuccessPopup}
              submitText="close"
              title="article"
            >
              <Div>
                <Trans>successfullyAddedArticle</Trans>
              </Div>
            </SuccessPopup>
          )}
          {error && (
            <ErrorPopup
              onSubmit={onCloseErrorPopup}
              submitText="close"
              title="errorOccurred"
            >
              {error.message}
            </ErrorPopup>
          )}
        </Div>
      </Div>
    </Div>
  );
}
